import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ArticleModalComponent } from "src/app/components/modal-components/article-modal/article-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketService } from "src/app/services/basket.service";
import { ApplicationService } from "src/app/services/application.service";
import { BasketAction } from "src/app/interfaces/basket-action";
import { SetItemsComponent } from "src/app/components/article-components/set-items/set-items.component";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ConfirmDialogComponent } from "src/app/components/modal-components/confirm-dialog/confirm-dialog.component";
import { SetItemModalComponent } from "src/app/components/article-components/set-items/set-item-modal/set-item-modal.component";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { EditBasketArticleModalComponent } from "../../edit-basket-article-modal/edit-basket-article-modal.component";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { MandatorySelectModalComponent } from "../mandatory-select-modal/mandatory-select-modal.component";
import { Subscription, forkJoin } from "rxjs";
import { AccountService } from "src/app/services/account.service";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: "app-basket-article",
  templateUrl: "./basket-article.component.html",
  styleUrls: ["./basket-article.component.scss"],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('300ms ease-out',
                    style({  height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({  opacity: 1, "z-index": 0 }),
            animate('200ms ease-in',
                    style({height: 0, opacity: 0 }))
          ]
        )
      ]
    )]
})
export class BasketArticleComponent implements OnInit {
  @Input() article: BasketArticle;
  @Input() parentArticle: BasketArticle;
  @Input() previewItem = true;
  @Input() basketId = -1;
  @Input() showDetailed = true;
  @Input() preorder = false;
  @Input() isChild = false;
  @Input() type: string;
  @Input() showCheckoutOption = true;
  @Input() quickorder;
  @Input() basketLocked: boolean;
  @Output()
  articleSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  preorderQtyChanged: EventEmitter<any> = new EventEmitter<any>();


  @Input() showShopDropdown: boolean; // show shop selection
  @Input() lockedDeliveryWeeks: boolean; // show delivery week
  @Input() compact: boolean = false; // compact mode
  placeholder = "assets/images/bicycle-solid.svg";
  showroomMode: boolean;
  loading: boolean;
  showDealerOptions = !this.applicationService.isPartnerPortal();

  // DELIVERYNOTICE2022
  deliveryDateNotice2022: string = "";
  companyGroupCode: string = "";
  expanded = false;
  updateArticleCall: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private basketService: BasketService,
    public applicationService: ApplicationService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private translateService: TranslateService,
    private accountService: AccountService
  ) {
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(false);
  }

  ngOnInit() {
    // DELIVERYNOTICE2022
    this.deliveryDateNotice2022 = this.translateService.instant(
      "DELIVERY_DATE_NOTICE_2022"
    );
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.basketService.closeBasketArticleModal();
  }

  openModal(event) {
    if (
      (event &&
        typeof event.srcElement !== "undefined" &&
        event.srcElement.classList.contains("editArticle")) ||
      event.srcElement.classList.contains("editMandatoryArticle")
    ) {
      if (event.srcElement.classList.contains("editArticle")) {
        this.editArticle(event);
      }
    } else {
      if (typeof this.article["setitems"] === "undefined" ) {
        this.basketService.showBasketArticle(
          this.article.item_details,
          ArticleModalComponent, this.preorder
        );
      } else {
        const modalRef = this.modalService.open(SetItemModalComponent, {
          centered: true,
          size: "lg",
          container: "#modalContainer",
        });
        modalRef.componentInstance.showDetailed = true;
        modalRef.componentInstance.article = this.article.item_details;
        modalRef.componentInstance.basketId = this.basketId;
        modalRef.componentInstance.quantity = this.article.quantity;
        modalRef.componentInstance.preorder = this.preorder;
        modalRef.componentInstance.article.basketArticleId = this.article.id;
        modalRef.componentInstance.preSelectedArticles =
          this.article["setitems"];
      }
    }
  }

  selectArticle(data) {
    if (data.ctrlKey) {
      this.article.checkout = !this.article.checkout;
      this.updateCheckoutStatus();
    }
  }

  updateCheckoutStatus() {
    let calls = [];

    // Add all childeren the checkout list
    if (this.article && this.article.children && this.article.children.length) {
      this.article.children.forEach((a) => {
        a.checkout = false;
        calls.push(
          this.basketService.updateArticleCheckoutStatus(
            this.basketId,
            [a],
            a.checkout
          )
        );
      });
    }

    // Add parent article to checkout list
    calls.push(
      this.basketService.updateArticleCheckoutStatus(
        this.basketId,
        [this.article],
        this.article.checkout
      )
    );

    // Execute calls
    forkJoin(calls).subscribe((response) => {
      this.basketService.emitChange({
        type: "updateCheckoutStatusArticle",
        basketId: this.basketId,
        basketArticleId: this.article.id,
        article: this.article,
      });

      this.articleSelectionChanged.emit(this.article);
    });
  }

  shopTypeChanged(type: string) {
    this.article.order_type = type;
    if (type === "X") {
      this.article.sold = true;
    } else {
      this.article.sold = false;
    }
    this.articleChanged();
  }

  articleChanged(reloadBasket = false) {

    if (!this.quickorder) {
      this.loading = true;

      this.article.remarks = this.article.remarks
        ? this.article.remarks.trim()
        : "";

      if (this.article.quantity) {

        // Abort previous call
        if (typeof this.updateArticleCall !== "undefined") {
          this.updateArticleCall.unsubscribe();
        }

        this.updateArticleCall = this.basketService
          .updateArticle(this.basketId, this.article)
          .subscribe((response: ApiResponse) => {

            this.loading = false;
            this.articleSelectionChanged.emit(this.article);

            // Trigger update
              this.basketService.emitChange({
              type: "updateArticle",
              basketId: this.basketId,
              basketArticleId: this.article.id,
              article: this.article,
              reloadBasket: reloadBasket
            });
          });
      }

      return false;
    }
  }

  deleteArticle() {
    const self = this;
    this.applicationService.showLoader(true);
    const promise = new Promise((resolve) => {
      if (self.basketId < 0) {
        resolve(self.article);
      } else {
        self.basketService
          .deleteArticle(self.basketId, self.article)
          .subscribe((response: ApiResponse) => {
            this.applicationService.hideLoader();
            if (response.success && response.result) {
              resolve(self.article);
            }
          });
      }
    });
    // Trigger update (only from basketpreview)
    // if (this.previewItem) {
    promise.then(() => {
      const basketAction = new BasketAction();
      basketAction.type = "deleteArticle";
      basketAction.article = self.article;
      basketAction.basketId = self.basketId;
      basketAction.basketArticleId = self.article.id;
      self.basketService.emitChange(basketAction);
    });
    //}
  }

  weekSelected(data) {
    const deliveryDate = String(data);
    this.article.updating_delivery = true;
    if (this.preorder) {
      this.basketService
        .deleteArticle(this.basketId, this.article)
        .subscribe((apiResponse: ApiResponse) => {
          const article = { ...this.article };
          article.single_article_code =
            article.item_details.single_article_code;
          article.delivery_week = deliveryDate;
          this.basketService.addArticlesToBasket([article], this.basketId);
        });
    } else {
      const deliveryWeek = deliveryDate.substr(4, 2);
      const deliveryYear = String(data).substr(0, 4);

      this.article.delivery_week = deliveryWeek;
      this.article.delivery_year = deliveryYear;
      this.basketService
        .updateArticle(this.basketId, this.article)
        .subscribe((response) => {
          this.article.updating_delivery = false;
        });
    }
  }

  articleSwitchChanged(value, key) {
    this.article[key] = value;
    this.articleChanged();
  }

  editArticle(event) {
    const modalRef = this.modalService.open(EditBasketArticleModalComponent, {
      centered: true,
      container: "#modalContainer",
      backdrop: "static",
    });
    modalRef.componentInstance.article = Object.assign({}, this.article);
    modalRef.componentInstance.lockedDeliveryWeeks = this.lockedDeliveryWeeks;
    modalRef.componentInstance.showShopDropdown = this.showShopDropdown;
    modalRef.componentInstance.basketId = this.basketId;
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.articleChanged.subscribe(
      (basketArticle: BasketArticle) => {
        this.article = basketArticle;
        this.articleChanged();
        modalRef.dismiss();
      }
    );
  }

  addToBasket() {
    this.article.remarks = "";
    this.alertService.showInfoNotification(
      this.translateService.instant("ARTICLE_ADDED")
    );
    this.articleChanged();
  }

  selectMandatory(article?: Article) {
    const modalRef = this.modalService.open(MandatorySelectModalComponent, {
      centered: true,
      container: "#modalContainer",
      backdrop: "static",
      size: "lg",
    });
    const parentArticle = (
      article ? article : { ...this.parentArticle }
    ) as BasketArticle;
    parentArticle.item_details.mandatory_battery = true;
    modalRef.componentInstance.basketArticleId = parentArticle.id;
    modalRef.componentInstance.basketId = this.basketId;
    modalRef.componentInstance.header =
      this.translateService.instant("REQUIRED_ITEM");
    modalRef.componentInstance.preorder = this.preorder;
    modalRef.componentInstance.article = parentArticle;
    modalRef.componentInstance.maxQuantity = parentArticle.quantity;
  }

  // DELIVERYNOTICE2022
  showDeliveryDateNotice2022(article) {
    if (
      article.stock_year >= 2022 &&
      article.stock_year < 9999 &&
      (this.companyGroupCode === "WG" || this.companyGroupCode === "GHO") &&
      article.item_type === 'Bicycles'
    ) {
      return this.deliveryDateNotice2022;
    }
  }

  checkQty(article: BasketArticle) {
    if (!article.quantity) {
      article.quantity = article.item_details.min_order_amount ? article.item_details.min_order_amount : 1;
    }  else if (article.quantity > 999) {
      article.quantity = 999;
    }
  }

  editPreorderQty() {
    this.preorderQtyChanged.emit(true)
  }
}
