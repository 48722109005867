<div class="row">
  <div class="col-md-12 bg-transparent">
    <div class="card-body section">
      <h5>{{(modelType === 'focusmodels' ? 'BASE_MODELS' : 'DEMO_MODELS')|translate}}</h5>
      <app-cms-page-content [pageId]="pageIds[modelType]" style="color: #5f5f5f" *ngIf="showContent"></app-cms-page-content>
      <ng-content></ng-content>
      <div class="row ms-0">
        <ng-container *ngFor="let modelGroup of selectedModels | groupBy:'brand';">
          <ng-container *ngIf="selectedBrands.indexOf(modelGroup.key) >= 0">
            <div class="col-12 col-sm-12 col-md-6 col-lg ps-0">
              <b>{{modelGroup.key.toUpperCase()|translate}}</b>
              <ul class="list-group mb-2">
                <ng-container *ngFor="let model of modelGroup.value; let i = index">
                  <li class="list-group-item py-1 clickable" *ngIf="model.country === yearAgreementModel.countryCode || !model.country">
                    <div class="form-check form-switch">
                      <input type="checkbox" class="form-check-input"
                        [id]="index + '_' + modelType + '_bike_' + modelGroup.key + '_' + i" [value]="true"
                        [(ngModel)]="model.selected" (change)="selectionChanged()">
                      <label class="form-check-label w-100  clickable withOpacity"
                        [for]="index + '_' + modelType + '_bike_'+ modelGroup.key + '_' + i">{{model.model_name}}</label>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
