import { Article } from "./article";
import { Price } from "./price";

export interface QuickOrderItem {
  searchText: string;
  amount: number;
  quantity: number;
  item_details: Article;
  single_article_code: string;
  description: string;
  delivery_week: string;
  id: number;
  delivery_year: number;
  unknown: boolean;
  stockStatus: number;
  image: string;
  caption: string;
  brand: string;
  company_id: string;
  alert: boolean;
  selected: boolean;
  thumbs: string[];
  net_price: Price;
  calculated_net_price: Price;
  retail_price: Price;
  volume_price: Price[];
  volumePrices: any;
}

export class QuickOrderItem implements QuickOrderItem {
  below_volume_amount: boolean;
  sum: number;
  constructor(searchText: string, quantity: number, alert = false) {
    this.searchText = searchText;
    this.amount = quantity;
    this.alert = alert;
  }
}
