<ng-container *ngIf="groupedArticles">
  <div [ngClass]="{ preorder: preorder }">
    <div class="mx-0 row">
      <div class="ps-2 col-12">
        <h4 *ngIf="header">
          <input
            type="checkbox"
            *ngIf="!compact && !checkout && showCheckoutOption && !basketLocked"
            [(ngModel)]="selected"
            (change)="selectAll($event)"
            id="type_{{ header }}"
          /><label class="pt-1 mb-0 ps-2" for="type_{{ header }}">
            {{ header | translate }} <ng-content></ng-content>
            <span *ngIf="!compact && showCheckoutOption"
              >({{ groupedArticles.checkoutCount }})</span
            ></label
          >
        </h4>
      </div>
      <div class="col-6">
        <ng-content select="[select-week]"></ng-content>
      </div>
    </div>
    <ul
      class="mb-0 list-group list-group-flush col-md-12 ps-sm-1 ps-3"
      *ngIf="!compact"
    >
      <ng-container *ngFor="let article of groupedArticles.items; trackBy: trackByItem; let i = index">
        <li
          class="list-group-item ps-1"
          *ngIf="article.item_details && (preorder || (!preorder && (!article.is_mandatory || !article.parent_found))) && !checkout && (groupedArticles.items.length <= viewIndex || i < viewIndex)"
        >
          <app-basket-article
            class="row"
            [ngClass]="[article.children ? 'hasChildren' : '', preorder ? 'preorder-article' : '']"
            [article]="article"
            (articleSelectionChanged)="selectionChanged()"
            (preorderQtyChanged)="editPreorder()"
            [showCheckoutOption]="showCheckoutOption"
            [basketId]="basketId"
            [previewItem]="false"
            [preorder]="preorder"
            [showShopDropdown]="showShopDropdown"
            [type]="type"
            [parentArticle]="article"
            [lockedDeliveryWeeks]="lockedDeliveryWeeks"
            [basketLocked]="basketLocked"
            [compact]="compactMode"
            *ngIf="groupedArticles.items.length <= viewIndex || i < viewIndex"

          >
            <div
              class="col d-none d-sm-block"
              *ngIf="
                preorder &&
                applicationService.getSelectCompanyGroupCode() !== 'ANL'
              "
            >
              <i class="material-icons clickable withOpacity" (click)="editPreorder()"> edit </i>
            </div>
          </app-basket-article>
          <app-checkout-article
            *ngIf="article.item_details && checkout"
            [showShopType]="showShopDropdown"
            [readonly]="readonly"
            [article]="article"
            [basketLocked]="basketLocked"
          ></app-checkout-article>
        </li>
        <li
          class="list-group-item text-danger col-12 p-2 my-2 deleted-article rounded"
          *ngIf="!article.item_details && !checkout"
        >
          {{ article.article_code }} {{ "STATUS5" | translate }}
          <button
            class="btn btn-danger btn-sm"
            (click)="deleteArticle(article)"
          >
            {{ "DELETE" | translate }}
          </button>
        </li>
      </ng-container>
      <li class="list-group-item bg-dark text-white text-center p-2 rounded clickable withOpacity" *ngIf="!checkout && groupedArticles.items.length > viewIndex" (click)="showMore()">{{"SHOW_MORE"|translate}} ({{viewIndex}} - {{nextResultsEnd(viewIndex, resultsPerView)}})</li>
      <li class="list-group-item text-center clickable withOpacity mt-2" *ngIf="!checkout && groupedArticles.items.length > viewIndex" (click)="viewIndex = groupedArticles.items.length">{{"SHOW_ALL_ARTICLES"|translate}}</li>
    </ul>
    <ul
      class="pb-0 mb-0 list-group list-group-flush col-md-12 ps-1 pe-0 pt-0 pt-sm-2"
      *ngIf="compact"
    >
      <ng-container *ngFor="let article of groupedArticles.items">
        <li class="list-group-item" *ngIf="article.item_details && !article.is_mandatory">
          <app-basket-article
            class="row"
            [article]="article"
            (articleSelectionChanged)="selectionChanged()"
            [basketId]="basketId"
            [previewItem]="true"
            [basketId]="basketId"
            [basketLocked]="basketLocked"
          ></app-basket-article>
        </li>
      </ng-container>
    </ul>

    <ul
      class="pb-0 mb-0 list-group list-group-flush col-md-12 ps-1"
      *ngIf="checkout"
    >
      <ng-container *ngFor="let article of groupedArticles.items">
        <li class="list-group-item" *ngIf="article.item_details && (preorder || (!preorder && (!article.is_mandatory || !article.parent_found)))">
          <app-checkout-article
            class="row"
            [article]="article"
            (articleSelectionChanged)="selectionChanged()"
            [readonly]="readonly"
            [preorder]="preorder"
            [basketLocked]="basketLocked"
          >
          </app-checkout-article>
        </li>
      </ng-container>
    </ul>

    <ul
      class="pt-0 list-group list-group-flush total col-md-12 ps-1"
      *ngIf="!compact"
    >
      <li class="list-group-item">
        <div
          class="row justify-content-end"
          [ngClass]="checkout ? 'px-0 px-sm-4' : 'mx-0 ps-2'"
        >
          <div class="col-5 col-sm-3 text-start ps-0 pe-sm-3 showroom-notice text-end">
            <i *ngIf="showroomMode" class="opacity-50">{{"RETAIL_PRICE"|translate}}</i>
          </div>
      
          <div class="ps-4 col-md-2">
            <ng-container *ngIf="preorder && checkout">
              <ng-container *ngIf="getQuantity('bicycle')"
                >{{ "TOTAL" | translate }}
                {{ ("BICYCLES" | translate).toLowerCase() }}:
                {{ getQuantity("bicycle") }}</ng-container
              >
              <br />
              <ng-container *ngIf="getQuantity('part')"
                >{{ "TOTAL" | translate }}
                {{ ("PARTS" | translate).toLowerCase() }}:
                {{ getQuantity("part") }}</ng-container
              >
            </ng-container>
          </div>
          <div class="text-end col-md-2 ps-sm-0 ps-md-4 text-md-left pt-1">
            <span
              *ngIf="preorder && !checkout"
              class="ms-1 ps-sm-0 ps-md-2 ps-lg-2 ps-xl-4"
              style="font-weight: bold"
            >
              {{ "QUANTITY" | translate }}: {{ getQuantity() }}
            </span>
          </div>
          <div class="col-md-4 ps-0 text-end groupSum pe-2 pe-sm-2 me-1 pe-sm-0 pt-1">
            <h5>{{ "TOTAL" | translate }}:&nbsp;<span [innerHTML]="sum | currencyConvert : currency"></span></h5>
          </div>
          <div class="col-md-12 d-flex justify-content-end pt-3 pe-2 pe-sm-3">
            <ng-container
            *ngIf="checkout && coupons && coupons.length && !showroomMode"
            >
              <button
                type="button"
                class="my-2 btn btn-success float-md-left d-block mt-md-0 "
                (click)="openCouponModal()"
              >
                {{ "OPEN_COUPONS" | translate }}
              </button>
            </ng-container>
            <button
              class="mt-4 me-2 btn btn-sm btn-dark mt-sm-0"
              *ngIf="!showCheckoutOption && !checkout && preorder"
              (click)="editPreorder()"
            >
              {{ "EDIT" | translate }}
            </button>
            <button
              class="mt-4 btn btn-success mt-sm-0"
              *ngIf="!showCheckoutOption && !checkout && preorder"
              (click)="checkoutPreorderSelection()"
            >
              {{ "CHECKOUT" | translate }}
            </button>
          </div>
        </div>
      </li>
    </ul>
    <ul
      class="pb-0 mb-0 list-group list-group-flush col-md-12 ps-1 pe-0 pe-sm-3"
      *ngIf="compact"
    >
      <li class="list-group-item">
        <div class="row">
          <div class="w-100 d-flex justify-content-end">
            <div class="ps-0 col-md-10"></div>
            <div class="col-md-2 hideForShowroomMode sum groupSum ps-2 pe-3 pe-sm-2 me-sm-1">
              <span [innerHTML]="sum | currencyConvert : currency"></span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
