
<app-style-anl *ngIf="selectedCompanyGroup === 'ANL'"></app-style-anl>
<app-style-gho *ngIf="selectedCompanyGroup !== 'ANL'"></app-style-gho>

<div id="content_container" [ngClass]="customClass + ' ' + selectedCompanyGroup">
  <app-selected-dealer-nav-bar
    *ngIf="isHelpdesk && showMainInterface"
    id="selectedDealerBar"
    class="px-3 py-1 d-block bg-secondary text-bold fixed-top white-text bg-dark"
  >
  </app-selected-dealer-nav-bar>
  <ng-container *ngIf="(environmentName !== 'production' ) && isLoggedIn$"
  ><div
    class="p-2 btn btn-light position-fixed fw-bold dev-info"
     *ngIf="showMainInterface"
  >
    {{ environmentName }}
    <small *ngIf="environmentApiWarning" class="bg-danger px-1"> {{environmentApiWarning}}</small>
    <app-test-settings/>
  </div></ng-container
  >
  <nav
    class="navbar navbar-expand-lg navbar-dark" [class.fixed-top]="navbarFixed"  [class.hasBasketBar]="showBasketBar"
    id="header"
    *ngIf="(showMainInterface && isLoggedIn$ | async) && !kioskMode"
    [ngClass]="headerClass"
    [class.hidden]="!isNavbarVisible"
  >

    <ng-container *ngIf="applicationService.newVersionAvailable"
      ><div
        class="p-2 position-fixed fw-bold bg-success rounded text-white clickable"
        style="right: 25px; bottom: 30px; z-index: 99999"
        (click)="updateApplication()"
      >
        {{ "NEW_VERSION" | translate }}
      </div></ng-container
    >

    <div class="container" [ngClass]="{ preview: isPreview }">
      <div
        class="ps-0 row collapse navbar-collapse d-flex justify-content-md-between"
        [ngClass]="showNav ? '' : 'hidden'"
        id="navbarSupportedContent"
        *ngIf="showMainInterface"
      >
        <div class="col-3 col-sm-1 d-flex align-items-center menu-container">
          <app-mega-menu class="w-auto"></app-mega-menu>
        </div>
        <div class="col-2 col-md-2 col-xl-2 logo-container">
          <app-logo
            class="d-flex align-items-center w-100"
            #logo
            [selectedCompanyGroup]="selectedCompanyGroup"
            [ngClass]="selectedCompanyGroup"
            (clickOutside)="logo.showCompanyDropdown = false"
          ></app-logo>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-5 d-flex justify-content-start ps-0">
          <app-search-bar
            id="searchbar"
            *ngIf="isDealer && !isPreview"
            class="px-0 position-relative d-flex justify-content-start w-100"
          ><app-quick-settings class="md-px-3 sm-ps-0" [placement]="'bottom'"></app-quick-settings>
          </app-search-bar>
        </div>
        <div
          *ngIf="isPreview && !showIcons"
          class="px-0 col-md-4"
        ></div>
        <div
          class="col pt-1 pe-1 counters d-flex pe-lg-1 pe-xl-2"
          *ngIf="!isPreview"
        >
          <app-juncker-loyalty-count class="me-0 me-sm-2" [compact]="true" *ngIf="applicationService.getSelectCompanyGroupCode() === 'ANL' && isDealer && showIcons"/>

          <span
            [routerLink]="selectedCompanyGroup + '/dealeroverview'"
            [ngbTooltip]="'MY_ACCOUNT' | translate"
            class="pt-1 mt-1 d-none clickable dealer-overview-icon d-sm-inline-block"
            style="transform: translateY(-1px)"
            *ngIf="showIcons"
          >
            <i id="menuButtonAccountIcon" class="material-icons light me-2">
              account_circle</i
            ></span
          >
          <app-dropshipment-counter class="d-none d-sm-block" *ngIf="isDealer && showIcons"></app-dropshipment-counter>
          <app-alert-counter *ngIf="showIcons"
            (alertDropdownOpen)="closeBasketPreview()"
          ></app-alert-counter>
          <app-basket-preview
            #basketPreview
            id="basketPreview"
            class="me-2"
            *ngIf="
              isDealer &&
              accountService.getAccountAccessOption('CanAccessBaskets')
            "
          ></app-basket-preview>
        </div>
      </div>
    </div>
  </nav>

  <p-scrollTop styleClass="custom-scrolltop global-to-top" [threshold]="100" icon="pi pi-arrow-up"></p-scrollTop>

  <div
    class="h-100 justify-content-center align-items-center" *ngIf="showSpinner"
  >
    <div class="spinner-grow text-primary spinner-loader d-flex align-items-center justify-content-center" role="status">
      <span class="visually-hidden">{{ "LOADING" | translate }}...</span>
      <i class="material-icons text-white">pedal_bike</i>
    </div>
  </div>
  <app-screen-saver
    *ngIf="screenSaverActive && showMainInterface"
    (active)="setScreenSaver($event)"
  >
    <img
      *ngIf="selectedCompanyGroup === 'ANL'"
      class="logo"
      src="./assets/images/Accentry-logo-black.svg"
      alt="Accentry"
      title="Accentry"
    />
    <img
      *ngIf="selectedCompanyGroup === 'GHO'"
      class="logo"
      src="./assets/images/Ghost-logo.png"
      alt="Ghost - Accentry"
      title="Ghost - Accentry"
    />

    <img
      *ngIf="selectedCompanyGroup === 'VAR'"
      class="logo"
      src="./assets/images/tunturi-accell-white.png"
      alt="Tunturi Sweden"
      title="Tunturi Sweden - Accentry"
    />
    <img
      *ngIf="selectedCompanyGroup === 'LAP'"
      class="logo"
      src="./assets/images/Acccell-sud.png"
      alt="Lapierre - Accentry"
      title="Lapierre - Accentry"
    />
    <img
      *ngIf="selectedCompanyGroup === 'COM'"
      class="logo"
      src="./assets/images/Comet-logo.png"
      alt="Comet - Accentry"
      title="Comet - Accentry"
    />
    <img
      *ngIf="selectedCompanyGroup === 'WG'"
      class="logo"
      src="./assets/images/Winora-logo.png"
      alt="Winora - Accentry"
      title="Winora - Accentry"
    />
    <img
      *ngIf="selectedCompanyGroup === 'WG'"
      class="logo"
      src="./assets/images/Winora-bike-parts-logo.svg"
      alt="E. Wiener Bike Parts - Accentry"
      title="E. Wiener Bike Parts - Accentry"
    />
  </app-screen-saver>
  <main id="wrapper" class="wrapper" [ngClass]="{'hasBasketBar': showBasketBar}">
    <div class="row">
      <div class="" [ngClass]="showBasketBar ? 'col-12' : ''">
        <router-outlet (activate)="scrollTop($event)"></router-outlet>
      </div>
      <div *ngIf="showBasketBar && showMainInterface" [hidden]="basketBarInvisible">
        <app-basket-bar/>
      </div>
    </div>
  </main>
  <app-footer
    id="footer"
    [selectedCompanyGroup]="selectedCompanyGroup"
    *ngIf="showMainInterface && !kioskMode"
    class="d-flex doNotPrint"
  >
  </app-footer>

  <app-stock-notifications-container
    *ngIf="showMainInterface && !kioskMode && showStockNotifcations"
  ></app-stock-notifications-container>
  <div id="modalContainer"></div>
  <div id="alertContainer"></div>
  <app-show-compare-button
    class="doNotPrint"
    *ngIf="showMainInterface && !kioskMode"
  ></app-show-compare-button>
  <app-leave-kiosk-mode-modal
    *ngIf="kioskCheckerActive"
    (kioskCheckerActive)="disableKioskMode()"
    (onExitKioskMode)="disableKioskMode()"
  ></app-leave-kiosk-mode-modal>
</div>
