<div class="container pt-3 mx-auto mt-5 row">
  <div class="text-center col-12 col-sm-4 text-sm-left">
    <app-accentry-logo class="d-block mx-auto mb-4 mt-3"/>
    <img class="logo" src="./assets/images/logos/accell-western-europe.svg" alt="Accell Western Europe"
      *ngIf="selectedCompanyGroup === 'ANL'" />
    <ng-container *ngIf="selectedCompanyGroup === 'LAP'">
      <img class="mt-2 logo" src="./assets/images/accell-sud.png" alt="Accell Sud" />
    </ng-container>
    <ng-container *ngIf="selectedCompanyGroup === 'TUN'">
      <img class="mt-2 logo" src="./assets/images/Tunturi-logo.png" alt="Tunturi" />
    </ng-container>
    <ng-container *ngIf="selectedCompanyGroup === 'WG'">
      <a href="https://winora-group.com/de/" target="_blank">
        <img class="logo" src="./assets/images/Winora-footer.png" alt="Winora"
          *ngIf="applicationService.checkIfDealerHasCompany(101)" />
      </a>
      <a href="https://www.bike-parts.de/" target="_blank">
        <img class="ms-2 logo" src="./assets/images/Winora-bike-parts-logo-color.png"
          *ngIf="applicationService.checkIfDealerHasCompany(102)" alt="E. Wiener Bike Parts" />
      </a>
    </ng-container>
    <ng-container *ngIf="selectedCompanyGroup === 'GHO'">
      <a href="https://www.ghost-bikes.com/" target="_blank">
        <img class="logo" src="./assets/images/Ghost-footer.png" alt="Ghost" />
      </a>
    </ng-container>
    <ng-container *ngIf="selectedCompanyGroup === 'VAR'">
      <img class="logo w-100" src="./assets/images/tunturi-accell-black.png" alt="Tunturi Sweden" />
    </ng-container>
    <ng-container *ngIf="selectedCompanyGroup === 'RAL'">
      <img class="logo w-100" src="./assets/images/raleigh_dark.svg" alt="Raleigh" title="Raleigh"
        style="max-width: 200px;" />
    </ng-container>
    <ng-container *ngIf="selectedCompanyGroup === 'COM'">
      <img class="me-2 logo" src="./assets/images/comet-logo-color.png" alt="Comet" />
    </ng-container>
    <span class="mt-5 d-block">Copyright Accell Group &copy; {{ currentYear }}</span>
  </div>
  <ng-container *ngIf="showLinks">
    <div class="mt-3 col-12 col-sm-4 items">
      <h4 class="mb-3">{{ "QUICKLINKS" | translate }}</h4>
      @defer (on viewport) {
        <app-quick-links class="links-homepage" [homepageQuicklinks]="true"></app-quick-links>
      } @placeholder {
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      }
    </div>
    <div class="mt-3 col-12 col-sm-4 items">
      <h4 class="mb-3">{{ "INFORMATION" | translate }}</h4>
      <ng-container *ngIf="selectedCompanyGroup !== 'WG' && selectedCompanyGroup !== 'GHO'">
        <ul class="list-group list-group-flush">
          <li class="list-group-item bg-transparent ps-0 py-1"><a *ngIf="selectedCompanyGroup === 'ANL'"
              [routerLink]="selectedCompanyGroup + '/companyPage/960422033'" class="link">{{ "REMOTE_SUPPORT" |
              translate }}</a></li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a *ngIf="selectedCompanyGroup === 'ANL'"
              [routerLink]="selectedCompanyGroup + '/companyPage/960422036'" class="link">{{ "CONTACT_COMPANIES" |
              translate }}</a></li>
          <li class="list-group-item bg-transparent ps-0 py-1">
            <a href="#" class="link" (click)="getLink('terms_and_conditions', $event)"
              *ngIf="selectedCompanyGroup !== 'RAL'">{{ "GENERAL_TERMS_AND_CONDITIONS" | translate }}</a>
          </li>
          <li class="list-group-item bg-transparent ps-0 py-1">
            <a href="#" class="link" [routerLink]="selectedCompanyGroup + '/companyPage/terms-conditions'"
              *ngIf="selectedCompanyGroup === 'RAL'">{{ "GENERAL_TERMS_AND_CONDITIONS" | translate }}</a>
          </li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a *ngIf="selectedCompanyGroup === 'COM'"
              [routerLink]="selectedCompanyGroup + '/companyPage/960422153'" class="link">{{ "PRIVACY_STATEMENT" |
              translate }}</a></li>
        </ul>
      </ng-container>
      <ng-container *ngIf="selectedCompanyGroup === 'WG'">
        <ul class="list-group list-group-flush">
          <li class="list-group-item bg-transparent ps-0 py-1">
            <a [routerLink]="selectedCompanyGroup + '/companyPage/allgemeine-geschaeftsbedingungen'" class="link">{{
              "GENERAL_TERMS" | translate }}</a>
          </li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a
              [routerLink]="selectedCompanyGroup + '/companyPage/960422118'" class="link">{{ "PRIVACY_STATEMENT" |
              translate }}</a>
          <li class="list-group-item bg-transparent ps-0 py-1">
            <a [routerLink]="selectedCompanyGroup + '/companyPage/kontakt'" class="link">{{ "CONTACT" | translate }}</a>
          </li>
          <li class="list-group-item bg-transparent ps-0 py-1">
            <a [routerLink]="selectedCompanyGroup + '/companyPage/960422136'" class="link" href="#">{{ "IMPRESSUM" |
              translate }}</a>
          </li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a
              [routerLink]="selectedCompanyGroup + '/companyPage/nutzungsbedingungen-datenschutz'" class="link">{{
              "GENERAL_TERMS_AND_CONDITIONS" | translate }}</a></li>
        </ul>
      </ng-container>
      <ng-container *ngIf="selectedCompanyGroup === 'GHO'">
        <ul class="list-group list-group-flush">
          <li class="list-group-item bg-transparent ps-0 py-1"><a
              [routerLink]="selectedCompanyGroup + '/companyPage/960421819'" class="link">{{ "GENERAL_TERMS" | translate
              }}</a></li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a
              [routerLink]="selectedCompanyGroup + '/companyPage/960422123'" class="link">{{ "PRIVACY_STATEMENT" |
              translate }}</a></li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a
              [routerLink]="selectedCompanyGroup + '/companyPage/kontakt'" class="link">{{ "CONTACT" | translate }}</a>
          </li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a
              [routerLink]="selectedCompanyGroup + '/companyPage/960422209'" class="link" href="#">{{ "IMPRESSUM" |
              translate }}</a></li>
          <li class="list-group-item bg-transparent ps-0 py-1"><a
              [routerLink]="selectedCompanyGroup + '/companyPage/nutzungsbedingungen-datenschutz'" class="link">{{
              "GENERAL_TERMS_AND_CONDITIONS" | translate }}</a></li>
        </ul>
      </ng-container>
    </div>
  </ng-container>
</div>
