<div class="row">
  <div class="col-12">
    <app-agreement-alert *ngIf="accountAgreement" [sendDate]="accountAgreement.send" [signedDate]="accountAgreement.signed" [signed]="(accountAgreement && accountAgreement.locked && accountAgreement.signed) ? true : false" [sent]="(accountAgreement && accountAgreement.send && selectedDealer) ? true : false"></app-agreement-alert>
  </div>
  <div class="col">
    <select
      class="mb-2 form-control agreements"
      *ngIf="
        accountAgreements && accountAgreements.length > 1 && selectedDealer
      "
      (change)="changeAgreement()"
      [(ngModel)]="selectedOldAgreement"
    >
      <option value="">{{ "SELECT" | translate }}</option>
      <option
        [value]="accountAgreement.id"
        *ngFor="let accountAgreement of accountAgreements"
      >
        {{ accountAgreement.year }}
        <ng-container
          *ngIf="accountAgreement.locked && accountAgreement.signed"
        >
          ({{ "SIGNED" | translate }}:
          {{ _getSignedDate(accountAgreement.signed) }})
        </ng-container>
      </option>
    </select>
    <button
      class="float-left my-2 btn btn-success"
      *ngIf="creatableYears.length && selectedDealer"
      (click)="newAgreement()"
    >
      {{ "NEW_AGREEMENT" | translate }}
    </button>
  </div>
</div>
<nav
  ngbNav
  #nav="ngbNav"
  [destroyOnHide]="false"
  (navChange)="tabChange($event)"
  [activeId]="selectedTab"
  class="nav-pills"
>
  <ng-container *ngIf="!selectedDealer">
    <ng-container ngbNavItem="dealerInformation">
      <h3>{{ "OVERVIEW" | translate }}</h3>
      <ng-template ngbNavContent>
        <ul class="list-group all-ya-items">
          <li
            class="list-group-item d-flex align-items-center"
            *ngFor="let agreement of this.accountAgreements"
          >
            <b>{{ "YEAR_AGREEMENT" | translate }} {{ agreement.year }}</b>
            <ng-container>
              <i *ngIf="agreement && agreement.signed"
                >&nbsp;- {{ "SIGNED" | translate }}:
                {{ _getSignedDate(agreement.signed) }}</i
              >
              <button
                class="ms-auto btn btn-sm btn-success"
                (click)="
                  agreement.signed ? getPdf(agreement) : openPopup(agreement)
                "
              >
                {{ "VIEW" | translate }} (Pdf)
              </button>
            </ng-container>

            <span *ngIf="!agreement.send" class=" pt-1 me-2">
              {{ "IN_PROCESS" | translate }}
            </span>
          </li>
        </ul>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="accountAgreement && selectedDealer">
    <ng-container *ngIf="!accountAgreement.locked && !loading">
      <ng-container ngbNavItem="dealerInformation">
        <a ngbNavLink>{{ "DEALER" | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-4">
              <app-dealer-information
                [affiliates]="affiliates"
                [selectedDealer]="selectedDealer"
                [selectedDealerInformation]="selectedDealerInformation"
                [accountAgreement]="accountAgreement"
              >
              </app-dealer-information>
            </div>
            <div class="col-4">
              <app-year-agreement-brand-selection
                *ngIf="accountAgreement && !yearAgreementModel.locked"
                [yearAgreementModel]="yearAgreementModel"
                [accountAgreement]="accountAgreement"
                [isMain]="true"
                [affiliate]="mainAffiliate"
              ></app-year-agreement-brand-selection>
            </div>
            <div class="col-4">
              <div class="mx-0 row">
                <div class="card-body section">
                  <app-year-agreement-tab
                    *ngIf="accountAgreement"
                    [selectedTab]="selectedTab"
                    [yearAgreementModel]="yearAgreementModel"
                    [accountAgreement]="accountAgreement"
                    [type]="'dealer'"
                    [showMarginButton]="false"
                    [showCalculation]="false"
                    [parentClass]="'p-0'"
                    [isMain]="true"
                    [cardClass]="'col-sm-12 p-0 dealer'"
                  ></app-year-agreement-tab>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container
        ngbNavItem="brands"
        #tabLifestyle
        [disabled]="
          !yearAgreementModel['brands']['hasAccess'] ||
          yearAgreementModel.locked
        "
      >
        <a ngbNavLink>{{ "YA_MARGIN" | translate }}</a>
        <ng-template ngbNavContent>
          <app-year-agreement-tab
            *ngIf="accountAgreement"
            [selectedTab]="selectedTab"
            [yearAgreementModel]="yearAgreementModel"
            [accountAgreement]="accountAgreement"
            [totalTurnover]="totalTurnover"
            [isMain]="true"
            [type]="'brands'"
          >
          </app-year-agreement-tab>
        </ng-template>
      </ng-container>
      <ng-container
        ngbNavItem="specials"
        #tabSignature
        [disabled]="
          !yearAgreementModel['specials']['hasAccess'] ||
          yearAgreementModel.locked
        "
      >
        <a ngbNavLink>{{ "SPECIALS" | translate }}</a>
        <ng-template ngbNavContent>
          <app-year-agreement-tab
            *ngIf="accountAgreement"
            [selectedTab]="selectedTab"
            [yearAgreementModel]="yearAgreementModel"
            [accountAgreement]="accountAgreement"
            [isMain]="true"
            [type]="'specials'"
          >
          </app-year-agreement-tab>
        </ng-template>
      </ng-container>

      <ng-container
        ngbNavItem="distribution"
        #tabDistribution
        [disabled]="
          yearAgreementModel.locked ||
          (!yearAgreementModel['brands']['hasAccess'] &&
            !yearAgreementModel['specials']['hasAccess'])
        "
      >
        <a ngbNavLink
          ><span
            [ngClass]="distributionInvalid ? 'text-white bg-danger rounded p-1' : ''"
            >{{ "DISTRIBUTION" | translate }}
            <span *ngIf="distributionInvalid">*</span></span
          ></a
        >
        <ng-template ngbNavContent>
          <app-purchase-distribution
            [yearAgreementModel]="yearAgreementModel"
            [hidden]="selectedTab !== 'distribution'"
            [tab]="selectedTab"
            [overviewType]="'distribution'"
          >
          </app-purchase-distribution>
        </ng-template>
      </ng-container>
       <ng-container
        ngbNavItem="demomodels"
        #tabDemoModels
        [disabled]="yearAgreementModel.locked || !accountAgreement || !yearAgreementModel['brands']['hasAccess']"
        *ngIf="yearAgreementModel.year <= 2022"
      >
        <a ngbNavLink>{{ "DEMO_MODELS" | translate }}</a>
        <ng-template ngbNavContent>
          <app-year-agreement-models-selection
            [selectedTab]="selectedTab"
            *ngIf="accountAgreement"
            [yearAgreementModel]="yearAgreementModel"
            [accountAgreement]="accountAgreement"
            [modelType]="'demomodels'"
            [affiliate]="mainAffiliate"
            [selectedAffiliate]="mainAffiliate"
          >
          </app-year-agreement-models-selection>
        </ng-template>
      </ng-container>
      <ng-container
        ngbNavItem="focusmodels"
        #tabBaseModels
        *ngIf="allAffiliates.length === 1 || yearAgreementModel.year <= 2022"
        [disabled]="
          yearAgreementModel.locked ||
          !accountAgreement ||
          !yearAgreementModel['brands']['hasAccess']
        "
      >
        <a ngbNavLink>{{ "BASE_MODELS" | translate }}</a>
        <ng-template ngbNavContent>
          <app-year-agreement-models-selection
            [selectedTab]="selectedTab"
            *ngIf="accountAgreement"
            [yearAgreementModel]="yearAgreementModel"
            [accountAgreement]="accountAgreement"
            [modelType]="'focusmodels'"
            [affiliate]="mainAffiliate"
            [isMain]="true"
            [selectedAffiliate]="mainAffiliate"
          >
          </app-year-agreement-models-selection>
        </ng-template>
      </ng-container>

      <ng-container
        ngbNavItem="planning"
        #tabDistribution
        [disabled]="
          yearAgreementModel.locked ||
          (!yearAgreementModel['brands']['hasAccess'] &&
            !yearAgreementModel['specials']['hasAccess'])
        "
      >
        <a ngbNavLink
          ><span
            >{{ "PLANNING" | translate }}
            <span *ngIf="planningInvalid">*</span></span
          ></a
        >
        <ng-template ngbNavContent>
          <app-purchase-distribution
            [yearAgreementModel]="yearAgreementModel"
            *ngIf="selectedTab === 'planning'"
            [overviewType]="'planning'"
          ></app-purchase-distribution>
        </ng-template>
      </ng-container>
      <ng-container
        ngbNavItem="affiliates"
        [disabled]="yearAgreementModel.locked"
        *ngIf="affiliatesList.length > 1 || (affiliates && yearAgreementModel.year < 2023)"
      >
        <a ngbNavLink>
            <span
            [ngClass]="affiliatesInvalid ? 'text-white bg-danger rounded p-1' : ''"
            >{{ "AFFILIATES" | translate }}
            <span *ngIf="distributionInvalid">*</span></span
          >
        </a>
        <ng-template ngbNavContent>
          <div class="mx-0 row affiliates">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-4">
                  <app-affiliate-information
                    [yearAgreementModel]="yearAgreementModel"
                    [accountAgreement]="accountAgreement"
                    [affiliates]="affiliates"
                    (affiliateSelected)="selectedSubAffiliate = $event"
                    [selectedSubAffiliate]="selectedSubAffiliate"
                    *ngIf="accountAgreement"
                  >
                  </app-affiliate-information>
                </div>
                <div class="col-8">
                  <h5 *ngIf="selectedSubAffiliate">
                    {{ "AFFILIATE" | translate }}: {{ selectedSubAffiliate }}
                    <ng-container *ngIf="selectedSubAffiliate === mainAffiliate"> ({{"MAIN_AFFILIATE"|translate}})</ng-container>
                  </h5>
                  <ng-container
                    *ngFor="
                      let affiliate of allAffiliates;
                      let i = index
                    "
                  >
                   <app-year-agreement-brand-selection
                      *ngIf="accountAgreement && !yearAgreementModel.locked"
                      [yearAgreementModel]="yearAgreementModel"
                      [accountAgreement]="accountAgreement"
                      [hidden]="
                        allAffiliates.length > 1 &&
                        selectedSubAffiliate &&
                        selectedSubAffiliate != affiliate
                      "
                      [index]="i"
                      [affiliate]="affiliate"
                      (brandSelected)="changeTs = $event"
                    >
                      <div
                        [hidden]="
                          allAffiliates.length > 1 &&
                          selectedSubAffiliate &&
                          selectedSubAffiliate != affiliate
                        "
                      ></div>
                      <app-year-agreement-tab
                        *ngIf="accountAgreement"
                        [selectedTab]="selectedTab"
                        [yearAgreementModel]="yearAgreementModel"
                        [accountAgreement]="accountAgreement"
                        [affiliate]="affiliate"
                        [hidden]="
                          allAffiliates.length > 1 &&
                          selectedSubAffiliate &&
                          selectedSubAffiliate !== affiliate
                        "
                        [onlyAffiliateOption]="true"
                        [showCalculation]="false"
                        [type]="'Brands'"
                        class="d-block"
                        style="margin-left: -1.25rem"
                      >
                      </app-year-agreement-tab>
                    </app-year-agreement-brand-selection>
                    <app-year-agreement-tab
                      *ngIf="
                        accountAgreement &&
                        !affiliate['is_main'] &&
                        selectedSubAffiliate
                      "
                      [accountAgreement]="accountAgreement"
                      [hidden]="
                        allAffiliates.length > 1 &&
                        selectedSubAffiliate &&
                        selectedSubAffiliate != affiliate
                      "
                      [selectedTab]="selectedTab"
                      [yearAgreementModel]="yearAgreementModel"
                      [accountAgreement]="accountAgreement"
                      [type]="'dealer'"
                      [showMarginButton]="false"
                      [showCalculation]="false"
                      [cardClass]="'col-sm-12'"
                      [parentClass]="'section card-body p-0'"
                      [affiliate]="affiliate"
                    ></app-year-agreement-tab>
                    <app-year-agreement-models-selection
                      [selectedTab]="selectedTab"
                      *ngIf="
                        accountAgreement &&
                        !affiliate['is_main'] &&
                        selectedSubAffiliate
                      "
                      [showContent]="selectedSubAffiliate === affiliate"
                      [yearAgreementModel]="yearAgreementModel"
                      [accountAgreement]="accountAgreement"
                      [modelType]="'focusmodels'"
                      [affiliate]="affiliate"
                      [selectedAffiliate]="selectedSubAffiliate"
                      [index]="i"
                      [hidden]="
                        allAffiliates.length > 1 &&
                        selectedSubAffiliate &&
                        selectedSubAffiliate != affiliate
                      "
                      [changeTs]="changeTs"
                    >
                    </app-year-agreement-models-selection>
                     <app-year-agreement-models-selection
                      [selectedTab]="selectedTab"
                      *ngIf="accountAgreement &&
                        accountAgreement.year <= 2022 &&
                        !affiliate['is_main'] &&
                        selectedSubAffiliate
                      "
                      [yearAgreementModel]="yearAgreementModel"
                      [accountAgreement]="accountAgreement"
                      [modelType]="'demomodels'"
                      [affiliate]="affiliate"
                      [selectedAffiliate]="selectedSubAffiliate"
                      [index]="i"
                      [hidden]="
                        allAffiliates.length > 1 &&
                        selectedSubAffiliate &&
                        selectedSubAffiliate != affiliate
                      "
                      [changeTs]="changeTs"
                    >
                    </app-year-agreement-models-selection>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container ngbNavItem="tabSummary" #tabSummary>
      <a ngbNavLink>{{ "YEARGREEMENT_SUMMARY" | translate }}</a>
      <ng-template ngbNavContent>
        <app-year-agreement-summary
          #summary
          [yearAgreementModel]="yearAgreementModel"
          [accountAgreement]="accountAgreement"
          *ngIf="!refreshing"
          (saved)="save()"
        >
          <div class="d-inline me-1">
            <button
              *ngIf="
                accountAgreement &&
                !yearAgreementModel.locked &&
                selectedTab === 'tabSummary'
              "
              class=" mt-3 btn btn-primary btn-lg"
              [disabled]="
                yearAgreementModel.disabled || summary.agreementSubmitted
              "
              (click)="sendAgreement(summary)"
            >
              {{ "SEND" | translate }}
            </button>
          </div>
        </app-year-agreement-summary>
      </ng-template>
    </ng-container>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
<div class="d-flex justify-content-end">
  <button
  *ngIf="
    selectedDealer &&
    accountAgreement &&
    !yearAgreementModel.locked &&
    selectedTab !== 'tabSummary'
  "
  class=" mt-3 btn btn-primary btn-lg"
  [disabled]="yearAgreementModel.disabled"
  (click)="save()"
>
  {{ "SAVE" | translate }}
  </button>
  <button
    *ngIf="
      selectedDealer && accountAgreement && yearAgreementModel.locked && canReopen
    "
    class=" mt-3 btn btn-info btn-lg"
    (click)="reopenAgreement()"
  >
    {{ "RE_OPEN" | translate }}
  </button>
  <button
    *ngIf="
      selectedDealer && accountAgreement && !accountAgreement.signed && accountAgreement.send && canReopen
    "
    class="ms-2 mt-3 btn btn-warning btn-lg"
    (click)="revokeAgreement()"
  >
    {{ "REVOKE" | translate }}
  </button>
</div>




<ng-template #affiliateSelect>
  <div
    class="ps-0 my-2 col-12 affiliates"
    *ngIf="allAffiliates && allAffiliates.length > 1"
  >
    <label class="my-2">{{ "AFFILIATE" | translate }}</label>
    <select
      class="form-control"
      [(ngModel)]="selectedAffiliate"
      style="min-width: 200px; max-width: 300px"
    >
      <option
        *ngFor="let affiliate of allAffiliates; let i = index"
        [value]="affiliate"
      >
        {{ affiliate }}
        <ng-container *ngIf="i == 0"
          >({{ "MAIN_AFFILIATE" | translate }})</ng-container
        >
      </option>
    </select>
  </div>
</ng-template>
