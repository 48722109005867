import { Component, OnInit, Input } from "@angular/core";
import * as _ from "underscore";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { ApplicationService } from "src/app/services/application.service";
import { BasketItemCount } from "src/app/interfaces/basket/basket-item-count";
import { ItemType } from "src/app/interfaces/article";

@Component({
  selector: "app-item-count",
  templateUrl: "./item-count.component.html",
  styleUrls: ["./item-count.component.scss"]
})
export class ItemCountComponent implements OnInit {
  @Input() items: any = [];
  @Input() item_count: BasketItemCount[];
  @Input() companyGroupCode: string
  @Input() preorder: boolean;

  partsCount = 0;
  bikesCount = 0;
  totalBikes: number;
  totalParts: number;
  language: string = "";

  constructor(private applicationService: ApplicationService) {}

  ngOnInit() {

    if (typeof this.item_count === "undefined") {
      let totalBikes = 0;
      let totalParts = 0;

      const all: any = _.filter(this.items, (a: BasketArticle) => {
        return a.article_code;
      }) as BasketArticle[];

      const bikes: any = _.filter(this.items, (a: BasketArticle) => {
        return a.item_type === "Bicycles" && a.article_code;
      }) as BasketArticle[];

      const parts: any = _.filter(this.items, (a: BasketArticle) => {
        return a.item_type !== "Bicycles" && a.article_code;
      }) as BasketArticle[];

      this.bikesCount = bikes.length;
      this.partsCount = all.length - this.bikesCount;
      for (const bike of bikes) {
        totalBikes += bike.quantity;
      }
      for (const part of parts) {
        totalParts += part.quantity;
      }
      this.totalBikes = totalBikes;
      this.totalParts = totalParts;
    } else {
      const bikes =  this.item_count.find((i: BasketItemCount) => {
        return i.article_type === ItemType.Bicycles;
      });
      const parts =  this.item_count.find((i: BasketItemCount) => {
        return i.article_type === ItemType.Parts;
      });
      this.totalBikes = bikes?.total_quantity;
      this.totalParts = parts?.total_quantity;
      this.bikesCount = bikes?.item_count;
      this.partsCount = parts?.item_count;
    }
    this.language = this.applicationService.getSelectedLanguage();
  }

  totalQuantity() {

    const bikes: any = _.filter(this.items, function(e) {
      return String(e["article_type"]).startsWith("Bicycles");
    });

  }
}
